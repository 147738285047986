import React from 'react';
import swal from 'sweetalert';

export const Button = ({ user }) => {
  const handleClick = () => {
    swal({
      text: 'You need to sign in to create a gathering',
      icon: 'info',
    });
  };
  let button = null;
  if (user) {
    button = (
      <a className="btn btn-ghost" href="/gatherings/new">
        Create Gathering
      </a>
    );
  } else {
    button = (
      <a className="btn btn-ghost" href="#" onClick={handleClick}>
        Create Gathering
      </a>
    );
  }
  return <div className="button-container">{button}</div>;
};
