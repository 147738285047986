import React from "react";
import { Navbar } from "./Navbar/Navbar";
import Footer from "./Footer";

const Privacy = ({ user = "" }) => {
  return (
    <div className="privacy-page_container">
      <Navbar user={user} />
      <div className="privacy-page_information">
        <h1>Privacy Policy for Pelle</h1>
        <p>
          <strong>Last updated:</strong> June 28, 2023
        </p>

        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use, and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>

        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions.
        </p>

        <h3>Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </li>
          <li>
            <strong>Company</strong> (referred to as either "the Company", "We",
            "Us" or "Our") refers to Pelle.
          </li>
          <li>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party.
          </li>
          <li>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </li>
          <li>
            <strong>Website</strong> refers to Pelle, accessible from{" "}
            <a href="https://www.pelle.live/">https://www.pelle.live/</a>.
          </li>
          <li>
            <strong>Service</strong> refers to the Website.
          </li>
          <li>
            <strong>Country</strong> refers to: Caribbean Netherlands.
          </li>
          <li>
            <strong>Service Provider</strong> refers to third-party companies or
            individuals employed by the Company to facilitate the Service.
          </li>
          <li>
            <strong>Third-party Social Media Service</strong> refers to any
            website or social network where a User can log in or create an
            account to use the Service.
          </li>
          <li>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </li>
          <li>
            <strong>Cookies</strong> are small files placed on Your device by a
            website, containing details of Your browsing history.
          </li>
          <li>
            <strong>Device</strong> refers to any device that can access the
            Service, such as a computer or phone.
          </li>
          <li>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself.
          </li>
        </ul>

        <h2>Collecting and Using Your Personal Data</h2>
        <h3>Types of Data Collected</h3>
        <p>
          <strong>Personal Data:</strong> We may ask You to provide Us with
          certain personally identifiable information that can be used to
          contact or identify You, such as:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Usage Data</li>
        </ul>

        <h3>Usage Data</h3>
        <p>Usage Data is collected automatically when using the Service.</p>

        <h3>Tracking Technologies and Cookies</h3>
        <p>
          We use Cookies and similar tracking technologies to track activity on
          Our Service and store certain information.
        </p>

        <h2>Use of Your Personal Data</h2>
        <p>
          The Company may use Personal Data for various purposes, including:
        </p>
        <ul>
          <li>To provide and maintain our Service.</li>
          <li>To manage Your Account.</li>
          <li>To perform a contract.</li>
          <li>To contact You via email, phone, or other methods.</li>
          <li>To provide You with news and offers.</li>
          <li>To manage Your requests.</li>
        </ul>

        <h3>Retention of Your Personal Data</h3>
        <p>
          The Company will retain Your Personal Data only as long as necessary
          for the purposes set out in this Privacy Policy.
        </p>

        <h3>Transfer of Your Personal Data</h3>
        <p>
          Your information may be transferred to computers outside of Your
          jurisdiction where data protection laws may differ.
        </p>

        <h2>Disclosure of Your Personal Data</h2>
        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data by law or in response to valid requests by public
          authorities.
        </p>

        <h2>Children's Privacy</h2>
        <p>
          Our Service does not address anyone under the age of 13. If We become
          aware that We have collected Personal Data from anyone under 13
          without verification, we take steps to remove it from Our servers.
        </p>

        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time and will notify You
          via email or a prominent notice on Our Service.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us at{" "}
          <a href="mailto:juansolano1034@gmail.com">juansolano1034@gmail.com</a>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
