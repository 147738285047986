import React from "react";
import { useEffect } from "react";
import axios from "axios";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import CloseIcon from "@material-ui/icons/Close";
import Avatar from "@material-ui/core/Avatar";
import Modal from "@material-ui/core/Modal";
import Badge from "@material-ui/core/Badge";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Recycle } from "./RecyclingBin/Recycle";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.secondary.main,
      position: "absolute",
      width: 355,
      height: 551,
      backgroundColor: theme.palette.background.paper,
      border: "1px solid rgba(0, 0, 0, 0.5)",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 8,
      margin: "45 auto",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: 678,
      height: 638,
      backgroundColor: theme.palette.background.paper,
      border: "1px solid rgba(0, 0, 0, 0.5)",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 8,
      margin: "0 auto",
    },
  },
}));

export const Notifications = (props) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [newNotifications, setNotifications] = React.useState([]);

  useEffect(() => {
    axios
      .get(`/api/v1/notifications`)
      .then((response) => setNotifications(response.data))

      .catch((error) => console.log(error));
  }, []);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleNotificationsType = (type, messageOwner) => {
    if (type === "Attendee")
      return (
        <div>
          {messageOwner} is now an {type.toLowerCase()}{" "}
        </div>
      );
    else return <div>{messageOwner} posted a message</div>;
  };

  const handleNotificationRouting = (id) => {
    axios
      .post(`/api/v1/notifications/${id}`)
      .then((response) => {
        const newArray = [...newNotifications];

        const notificationCallback = newArray.filter((el) => el.id != id);
        setNotifications(notificationCallback);
      })
      .catch((error) => console.log(error));
  };

  const notification =
    newNotifications.length >= 1 ? (
      newNotifications.slice(0, 4).map((el) => {
        const { message_owner, notifiable_type, id, photo, meetup_id } = el;
        return (
          <div className="notification">
            <Avatar className="notification-avatar" src={photo} />
            <p className="message">
              {handleNotificationsType(notifiable_type, message_owner)}
            </p>
            <div className="notification-actions">
              <DeleteForeverIcon
                onClick={() => handleNotificationRouting(id)}
                className="delete-notification"
              />
              <a href={`/gatherings/${meetup_id}`}>
                <VisibilityIcon className="see-notification" />
              </a>
            </div>
          </div>
        );
      })
    ) : (
      <div className="animation-container">
        <Recycle />
        <p className="animation-title">You are all up to date! 😊</p>
      </div>
    );

  const body = (
    <div style={modalStyle} className={classes.root}>
      <CloseIcon onClick={handleClose} className="notification-closing-icon" />
      <h2 className="notifications-header">Notifications</h2>
      <p className="notifications-description">
        Check the messages and attendees for your gatherings
      </p>
      <div className="notification-container">{notification}</div>
    </div>
  );

  return (
    <div>
      <div onClick={handleOpen} className="notification-wrapper">
        <Badge
          badgeContent={newNotifications.length}
          color="primary"
          className="notification-handle"
        >
          <ListItem>
            <NotificationsActiveIcon className="navigation-icons" />
          </ListItem>
        </Badge>
        <ListItem>
          <ListItemText className="navbar-text" primary={props.text} />
        </ListItem>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};
