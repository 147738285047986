import React from "react";
import { CardTemplate } from "./Card/Card";
import { MobileCardTemplate } from "./Card/MobileCard";
import { useEffect, useState } from "react";
import { Footer } from "./Footer/Footer";
import { Navbar } from "./Navbar/Navbar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AccessibleForwardIcon from "@material-ui/icons/AccessibleForward";
import SportsFootballIcon from "@material-ui/icons/SportsFootball";
import GrainIcon from "@material-ui/icons/Grain";
import PuffLoader from "react-spinners/PuffLoader";
import EventIcon from "@material-ui/icons/Event";
import axios from "axios";

const Gatherings = ({ pictures, user, likes }) => {
  const [value, setValue] = React.useState(0);
  const [newGatherings, setGatherings] = useState([]);
  const [newAttendees, setAttendees] = useState([]);
  const [newActions, setActions] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [newColorRecent, setColorRecent] = useState(false);
  const [newColorPopular, setColorPopular] = useState(false);
  const [newColorKids, setColorKids] = useState(false);
  const [newColorWheelchair, setColorWheelchair] = useState(false);
  const [newColorNearby, setColorNearby] = useState(false);
  const [newColorToday, setColorToday] = useState(false);
  const [newMobileFilters, setMobileFilters] = useState([]);

  useEffect(() => {
    setSpinner(true);
    axios
      .get(`/api/v1/all_gatherings`)
      .then((response) => {
        setGatherings(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`/api/v1/all/attendees`)
      .then((response) => setAttendees(response.data))
      .catch((error) => console.log(error));
    setTimeout(() => {
      setSpinner(false);
    }, 1500);
  }, []);

  const changeActions = (action) => {
    const filter = newActions.find((el) => el === action);

    if (!filter) {
      setActions(newActions.concat(action));
      let actions = [...newActions].concat(action);
      changeFilters(actions);
    }

    if (filter) {
      let deletedActions = [...newActions].filter((el) => el !== action);
      setActions(deletedActions);
      changeFilters(deletedActions);
    }
  };

  const changeFilters = (actions) => {
    let array = {};

    setMobileFilters(actions);

    setSpinner(true);

    if (actions.includes("Nearby")) {
      navigator.geolocation.getCurrentPosition(function (position) {
        array = {
          actions: actions,
          lat: position.coords.latitude,
          long: position.coords.longitude,
        };

        axios
          .post(`api/v1/all/`, array)
          .then((response) => {
            setGatherings(response.data);
            setSpinner(false);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      array = {
        actions: actions,
      };

      axios
        .post(`api/v1/all/`, array)
        .then((response) => {
          setGatherings(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      setSpinner(false);
    }
  };

  const removeFilters = () => {
    setColorRecent(false);
    setColorPopular(false);
    setColorKids(false);
    setColorWheelchair(false);
    setColorNearby(false);
    setColorToday(false);
    setActions([]);
    setMobileFilters([]);

    axios
      .get(`/api/v1/all_gatherings`)
      .then((response) => {
        setGatherings(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const meetups = [...newGatherings].map((gathering, index) => {
    const filterAttendees = newAttendees.filter(
      (attendee) => attendee.meetup_id === gathering.id
    );

    return (
      <div>
        {window && window.innerWidth >= 850 ? (
          <CardTemplate
            meetup={gathering}
            cardId={index}
            index={index}
            pictures={pictures}
            attendees={filterAttendees.length}
            likes={likes}
            user={user}
          />
        ) : (
          <MobileCardTemplate
            meetup={gathering}
            cardId={index}
            index={index}
            pictures={pictures}
            attendeesLength={filterAttendees.length}
            attendees={filterAttendees}
            likes={likes}
            user={user}
          ></MobileCardTemplate>
        )}
      </div>
    );
  });

  const mobileFilters = [...newMobileFilters].map((el) => {
    if (el === "Kids")
      return <p className="mobile-filter-action">Kids Access</p>;
    if (el === "Recents")
      return <p className="mobile-filter-action">Recently Created</p>;
    if (el === "Today")
      return <p className="mobile-filter-action">Happening Today</p>;
    if (el === "Wheelchair")
      return <p className="mobile-filter-action">Wheelchair Access</p>;
    else return <p className="mobile-filter-action">{el}</p>;
  });

  const dropDown = (
    <BottomNavigation
      className="navigation-filter"
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
    >
      <BottomNavigationAction
        onClick={() => {
          changeActions("Kids");
          setColorKids(!newColorKids);
        }}
        label="Kids Friendly"
        icon={<SportsFootballIcon className="filter-icons" />}
        className={newColorKids ? "Clicked" : "Unclicked"}
      />
      <BottomNavigationAction
        onClick={(event) => {
          changeActions("Recents");
          setColorRecent(!newColorRecent);
        }}
        label={"Recents"}
        icon={<RestoreIcon className="filter-icons" />}
        className={newColorRecent ? "Clicked" : "Unclicked"}
      />
      <BottomNavigationAction
        onClick={() => {
          changeActions("Popular");
          setColorPopular(!newColorPopular);
        }}
        label="Popular"
        icon={<FavoriteIcon className="filter-icons" />}
        className={newColorPopular ? "Clicked" : "Unclicked"}
      />
      <BottomNavigationAction
        onClick={() => {
          changeActions("Today");
          setColorToday(!newColorToday);
        }}
        label="Happening Today"
        icon={<EventIcon className="filter-icons" />}
        className={newColorToday ? "Clicked" : "Unclicked"}
      />
      <BottomNavigationAction
        onClick={() => {
          changeActions("Nearby");
          setColorNearby(!newColorNearby);
        }}
        label="Nearby"
        icon={<LocationOnIcon className="filter-icons" />}
        className={newColorNearby ? "Clicked" : "Unclicked"}
      />
      <BottomNavigationAction
        onClick={() => {
          changeActions("Wheelchair");
          setColorWheelchair(!newColorWheelchair);
        }}
        label="Wheelchair A"
        icon={<AccessibleForwardIcon className="filter-icons" />}
        className={newColorWheelchair ? "Clicked" : "Unclicked"}
      />
      <BottomNavigationAction
        onClick={() => removeFilters()}
        label="All Gatherings"
        icon={<GrainIcon className="filter-icons" />}
        className="reset"
      />
    </BottomNavigation>
  );

  let spinnerContent = null;

  if (spinner) {
    spinnerContent = (
      <div>
        <div className="filters-container">
          <div className="dropdown-container">{dropDown}</div>
        </div>
        <div className="sweet-loading">
          <PuffLoader size={300} color={"#FFCB46"} loading={true} />
        </div>
      </div>
    );
  } else {
    spinnerContent = (
      <div>
        <div className="filters-container">
          <div className="dropdown-container">{dropDown}</div>
        </div>
        <div className="filters-mobile">
          {mobileFilters.length ? (
            <button
              className="filters-mobile-button"
              onClick={() => removeFilters()}
            >
              Clear Filters
            </button>
          ) : null}
          <div className="filters-mobile-container">{mobileFilters}</div>
        </div>
        <div>
          {meetups && !!meetups.length ? (
            <div className="gatherings-filter-container">{meetups}</div>
          ) : (
            <div>
              <p className="no-gatherings-alert">No Gatherings were found</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar user={user} />
      <div
        className="main-body-container"
        style={{
          backgroundImage: `url('https://res.cloudinary.com/pelle/image/upload/v1624628041/Pelle-app-pictures-final/gatherings_tkylvw.jpg')`,
        }}
      >
        <div>{spinnerContent}</div>
        <div className="triangle-container-form"></div>
        <div className="footer-container">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Gatherings;
