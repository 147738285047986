import React from 'react';
import EventIcon from '@material-ui/icons/Event';

export const LikedCard = ({ title, startingDate, uuid }) => {
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  let date = new Date(startingDate);

  let monthName = months[date.getMonth()];

  let dayName = days[date.getDay()];

  return (
    <div>
      <a className="dashboarduser-link" href={`/gatherings/${uuid}`}>
        <div className="dashboardlike-container">
          <div className="dashboardlike-information-container">
            <p className="dashboardliked-start-date">
              {dayName +
                ', ' +
                date.getDate() +
                ' ' +
                monthName +
                ' ' +
                date.getFullYear()}
            </p>
            <p className="dashboardliked-title">{title}</p>
          </div>
        </div>
      </a>
    </div>
  );
};
