import React from "react";
import Avatar from "@material-ui/core/Avatar";
import SendIcon from "@material-ui/icons/Send";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import AccessibleIcon from "@material-ui/icons/Accessible";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { RegularMap } from "../Map/Map";

export const ShowMobile = ({
  pictures,
  description,
  newAttendees,
  visitingUser,
  newMessages,
  address,
  start_time,
  dayName,
  date,
  monthName,
  handleEndTime,
  end_time,
  kids_friendly,
  wheelchair_access,
  coord,
  click,
}) => {
  const getAnimation = () => {
    if (newMessages.length > 1)
      return <KeyboardArrowDownIcon className="comments-arrow" />;
  };

  return (
    <React.Fragment>
      <div className="show-page-container">
        <div className="picture-information-container">
          {pictures.length > 1 ? (
            <div className="carousel-container">
              <Carousel
                autoPlay={true}
                autoPlaySpeed={3000}
                responsive={responsive}
                infinite={true}
              >
                {getPicture()}
              </Carousel>
            </div>
          ) : (
            <img
              className="show-picture"
              src={"https://source.unsplash.com/1600x900/?Amsterdam"}
            />
          )}
          <div className="information-container">
            <div className="meetup-information-container">
              <div className="meetup-information">
                <LocationOnIcon className="meetup-icon" />
                <p className="meetup-text">{address}</p>
              </div>
              <div className="meetup-information">
                <DateRangeIcon className="meetup-icon" />
                <p className="meetup-text">
                  {parseInt(start_time.slice(11, 13)) >= 12
                    ? dayName +
                      ", " +
                      date.getDate() +
                      " " +
                      monthName +
                      " " +
                      date.getFullYear()
                    : dayName +
                      ", " +
                      date.getDate() +
                      " " +
                      monthName +
                      " " +
                      date.getFullYear()}
                </p>
              </div>
              <div className="meetup-information">
                <ScheduleIcon className="time-icon" />
                <p className="meetup-text">
                  {parseInt(start_time.slice(11, 13)) >= 12
                    ? start_time.slice(11, 16) +
                      " " +
                      "PM" +
                      "  -  " +
                      handleEndTime(end_time)
                    : start_time.slice(11, 16) +
                      " " +
                      "AM" +
                      "  -  " +
                      handleEndTime(end_time)}
                </p>
              </div>
              <div className="meetup-information">
                {kids_friendly ? (
                  <div className="kids-friendly-container">
                    <SportsSoccerIcon className="meetup-icon" />
                    <p className="meetup-text">Kids friendly gathering</p>
                  </div>
                ) : (
                  <div className="kids-friendly-container">
                    <SportsSoccerIcon className="meetup-icon" />
                    <p className="meetup-text">No kids friendly</p>
                  </div>
                )}
              </div>
              <div className="meetup-information">
                {wheelchair_access ? (
                  <div className="wheelchair-friendly-container">
                    <AccessibleIcon className="meetup-icon" />
                    <p className="meetup-text">Wheelchair access</p>
                  </div>
                ) : (
                  <div className="wheelchair-friendly-container">
                    <AccessibleIcon className="meetup-icon" />
                    <p className="meetup-text">No wheelchair access</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="description-container">
            <h3 className="description-title">What we'll do</h3>
            <p className="description-name">{description}</p>
            <div className="header-container">
              <h2 className="Attendees-header">
                Attendees {`(${newAttendees.length})`}
              </h2>
              <div className="attendee-container">
                {newAttendees &&
                  newAttendees.map((attendee, index) => {
                    return (
                      <div key={attendee.id}>
                        <div className="attendee-card">
                          <div className="avatar-card-container">
                            <a href={`/users/${attendee.user_id}/profile`}>
                              <Avatar
                                src={attendee.photo}
                                aria-label="recipe"
                                className="mobile-avatar-attendees"
                                id={index}
                              ></Avatar>
                            </a>
                          </div>
                          <p className="attendee-name">{attendee.name}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="messages-container">
                <div>
                  <div className="comments-container">
                    <h2 className="comments-header">
                      Comments {`(${newMessages.length})`}
                    </h2>
                    {getAnimation()}
                  </div>
                  <div className="comment-container">
                    {visitingUser}
                    <input
                      type="text"
                      className="comment-input"
                      placeholder="Leave a Comment"
                    ></input>
                    <SendIcon className="send-comment-button" onClick={click} />
                  </div>
                </div>
                <div className="comments-text-container">
                  <div>
                    {newMessages.map((message) => {
                      return (
                        <div key={message.id}>
                          <div className="comment-information">
                            <Avatar
                              src={message.photo}
                              className="avatar-message"
                            />
                            <div className="message-container-show">
                              <p className="message-user-name">
                                {message.user_name}
                              </p>
                              <p className="message-content">
                                {message.content}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="map-description-container">
            <div className="map-container">
              <RegularMap markers={coord} id="show-map" />
              <div
                onClick={(event) =>
                  window.open(
                    `https://www.google.com/maps/search/?api=1&query=${address}`,
                    "_blank"
                  )
                }
                className="google-maps-container"
              >
                <button id="google-maps"></button>
                <p className="google-paragraph">Google Maps</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
