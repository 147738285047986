import React from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken =
  'pk.eyJ1IjoianVhbnBhYmxvc29sYW5vMTIzIiwiYSI6ImNsNGxzZzhwMjBwYmQzYnFwY2hkd2hibDUifQ.UsE8SvxaBJpYJzucSSm9wQ';

export class RegularMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: props.markers.lng,
      lat: props.markers.lat,
      zoom: 13,
      toggle: false,
    };
  }
  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });
    map.addControl(new mapboxgl.NavigationControl());
    let el = document.createElement('div');
    el.className = 'marker';
    new mapboxgl.Marker(el)
      .setLngLat([this.props.markers.lng, this.props.markers.lat])
      .addTo(map);
  }

  render() {
    return (
      <div>
        <div
          ref={(el) => (this.mapContainer = el)}
          className="mapContainer"
          id="map-show"
        />
      </div>
    );
  }
}
