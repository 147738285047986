import React from "react";
import axios from "axios";
import { CardTemplate } from "./Card/Card";
import { MobileCardTemplate } from "./Card/MobileCard";
import "react-calendar/dist/Calendar.css";
import { SimpleAlerts } from "./Alert/Alert";
import { Footer } from "../components/Footer/Footer";
import { Navbar } from "./Navbar/Navbar";
import { ProfileModal } from "../components/ProfileModal/ProfileModal";
import MainDesktop from "../components/MainComponents/desktop";
import MainMobile from "./MainComponents/mobile";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetups: [],
      date: [],
      today: false,
      selectedDate: [],
      allGatherings: this.props.gatherings,
      user: this.props.user,
      users: this.props.users,
      totalMeetups: [],
      spinner: true,
      attendees: [],
      modal: this.props.modal,
      pickedWaste: "",
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.handleWastePicked = this.handleWastePicked.bind(this);
  }

  handleWastePicked() {
    const { totalMeetups, attendees } = this.state;
    const attendeesCount = (id) => {
      const attendeeNumber = attendees.filter(
        (attendee) => attendee.meetup_id == id
      );
      return attendeeNumber.length;
    };
    const hoursFilter = totalMeetups
      .map((el) => {
        return el.total_hours * attendeesCount(el.id) * 3.25;
      })
      .filter((el) => typeof el === "number")
      .reduce((a, c) => a + c, 0);
    this.setState({
      pickedWaste: (Math.round(hoursFilter) / 1000).toFixed(2) + " ton",
    });
  }

  componentDidMount() {
    axios
      .get(`/api/v1/meetupstotal`)
      .then((response) => {
        this.setState({ totalMeetups: response.data });
      })
      .catch((error) => console.log(error));

    axios
      .get(`/api/v1/all_gatherings`)
      .then((response) => {
        this.setState({ meetups: response.data.slice(0, 3) });
      })
      .catch((error) => console.log(error));

    axios
      .get(`/api/v1/all/attendees`)
      .then((response) => {
        this.setState({ attendees: response.data });
        this.handleWastePicked();
      })
      .catch((error) => console.log(error));
  }

  handleLogin() {
    return (
      <div>
        <a className="button-sign">Join Gathering</a>
        <a href={`/gatherings/new`} className="button-sign">
          Create Gathering
        </a>
      </div>
    );
  }

  handleSignUp() {
    return (
      <div>
        <a href={`users/sign_up`}>
          <button className="button-sign">Sign Up</button>
        </a>
        <a href={`users/sign_in`}>
          <button className="button-sign">Sign In</button>
        </a>
      </div>
    );
  }

  render() {
    const {
      attendees,
      allGatherings,
      meetups,
      date,
      today,
      users,
      pickedWaste,
    } = this.state;
    const { pictures, user, likes, modal } = this.props;
    let desktopCards = meetups.map((meetup, index) => {
      const filterAttendees = attendees.filter(
        (attendee) => attendee.meetup_id === meetup.id
      );
      return (
        <CardTemplate
          key={meetup.id}
          cardId={index}
          pictures={pictures}
          meetup={meetup}
          index={index}
          attendees={filterAttendees.length}
          user={user}
          likes={likes}
        ></CardTemplate>
      );
    });

    let mobileCards = meetups.map((meetup, index) => {
      const filterAttendees = attendees.filter(
        (attendee) => attendee.meetup_id === meetup.id
      );
      return (
        <MobileCardTemplate
          key={meetup.id}
          cardId={index}
          pictures={pictures}
          meetup={meetup}
          index={index}
          attendeesLength={filterAttendees.length}
          attendees={filterAttendees}
          user={user}
          likes={likes}
        ></MobileCardTemplate>
      );
    });
    const mark = [];
    let new_date = new Date();
    allGatherings.map((gathering) => {
      if (new Date(gathering.start_date) >= new_date) {
        mark.push(gathering.start_date);
      }
    });

    let alert = null;
    if (!today) {
      alert = (
        <div>
          <SimpleAlerts className="alert" />
          <div>
            <h1 className="current-gathering-title">Future Gatherings</h1>
          </div>
        </div>
      );
    } else {
      alert = (
        <div>
          <hr className="line-break"></hr>
          <h1 className="current-gathering-title">Current Gatherings</h1>
        </div>
      );
    }
    let array = [];
    if (date.length === 0) {
      meetups.map((element) => {
        const markers = { lat: element.latitude, lng: element.longitude };
        array.push(markers);
      });
    } else {
      date.map((element) => {
        const markers = { lat: element.latitude, lng: element.longitude };
        array.push(markers);
      });
    }
    const profileModal =
      user && !modal ? (
        <div>
          <ProfileModal user={user} />
        </div>
      ) : null;
    return (
      <div className="container-homepage">
        {profileModal}
        <Navbar user={this.state.user} />
        {window && window.innerWidth > 660 && (
          <MainDesktop
            {...{
              attendees,
              allGatherings,
              meetups,
              users,
              meetups,
              desktopCards,
              pickedWaste,
            }}
          />
        )}
        {window && window.innerWidth <= 650 && (
          <MainMobile
            {...{
              attendees,
              allGatherings,
              meetups,
              users,
              meetups,
              mobileCards,
              pickedWaste,
            }}
          />
        )}
      </div>
    );
  }
}

export default Main;
