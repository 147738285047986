import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { ProfileAnimation } from "../ProfileAnimation/ProfileAnimation";
import Slide from "@material-ui/core/Slide";
import axios from "axios";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      height: "36rem",
      borderRadius: 8,
      width: "25rem",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      height: 550,
      borderRadius: 8,
      width: 550,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  },
}));

export const ProfileModal = (props) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);

  const Transition = React.forwardRef(function Transition(props, ref) {
    console.log(props);
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const { user } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleModal = () => {
    axios.post(`/modals`).then((response) => console.log(response));
  };

  const body = (
    <div style={modalStyle} className={classes.root}>
      <CloseIcon
        className="close-icon"
        onClick={() => {
          handleModal();
          handleClose();
        }}
      />
      <div className="profile-modal-text-container">
        <h2 id="simple-modal-title">Welcome to Pelle</h2>
        <p id="simple-modal-description">Please complete your profile.</p>
      </div>
      <ProfileAnimation />
      <div className="profile-modal-button-container">
        <a href={`/users/${user.id}/edit`}>
          <button onClick={() => handleModal()} className=" button-sign-footer">
            Update your profile
          </button>
        </a>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        TransitionComponent={Transition}
      >
        {body}
      </Modal>
    </div>
  );
};
