import React from 'react';
import { useReducer } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import axios from 'axios';
import swal from 'sweetalert';

export const Drawer = ({ meetup_owner, meetup, user }) => {
  const [state, setState] = React.useState({
    left: false,
  });

  const [newMessage, setMessage] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      name: '',
      email: '',
      text: '',
    }
  );

  const handleRequest = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setMessage({ [name]: value });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleAlert = () => {
    swal('Please sign in to send emails to Organizers');
  };
  const handleSubmit = () => {
    let email = {
      name: newMessage.name,
      email: user.email,
      text: newMessage.message,
      user_email: meetup_owner.email,
      subject: meetup,
    };
    axios.post('/email', email).then((response) => {
      console.log(response);
      swal(
        'Email sent!',
        'The Organizer will reply directly to your email',
        'success',
        {
          buttons: {
            ok: true,
          },
        }
      ).then((value) => {
        switch (value) {
          case 'ok':
            setState({ left: false });
            break;
        }
      });
    });
  };
  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <button
            className="btn btn-ghost"
            id="button-drawer"
            onClick={toggleDrawer(anchor, true)}
          >
            Contact Organizer
          </button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <div className="drawer-container">
              <div className="drawer-titles">
                <h3>Contact</h3>
                <h3> {meetup_owner.name}</h3>
              </div>
              <div>
                <p className="drawer-label">Your Name</p>
                <input
                  name="name"
                  className="drawer-form"
                  onChange={handleRequest}
                ></input>
              </div>
              <div>
                <p className="drawer-label">Your Email</p>
                {user ? (
                  <input
                    value={user.email}
                    name="email"
                    className="drawer-form"
                    onSubmit={handleRequest}
                  ></input>
                ) : (
                  <input
                    name="email"
                    className="drawer-form"
                    onChange={handleRequest}
                  ></input>
                )}
              </div>
              <div>
                <p className="drawer-label">Message</p>
                <textarea
                  name="message"
                  id="drawer-textarea"
                  className="drawer-form"
                  onChange={handleRequest}
                ></textarea>
              </div>
              <div>
                {user ? (
                  <button
                    id="drawer-button"
                    className="btn btn-ghost"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    id="drawer-button"
                    className="btn btn-ghost"
                    onClick={handleAlert}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};
