import React from 'react';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';

mapboxgl.accessToken =
  'pk.eyJ1IjoianVhbnBhYmxvc29sYW5vMTIzIiwiYSI6ImNrOXdweXI4bjA4MDkzZXB4aGdpeW41enIifQ.FVaQh2AZeqCqGBwVqzvRHg';

export class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 4.867116659586616,
      lat: 52.3578866,
      zoom: 10.5,
      toggle: false,
    };
  }

  componentDidMount() {
    console.log(this.props.markers, 'markers-meetups');
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });
    map.addControl(new mapboxgl.NavigationControl());
    this.props.markers.forEach((marker) => {
      let el = document.createElement('div');
      el.className = 'marker';
      let toggle = false;
      el.addEventListener('click', function (e) {
        // Prevent the `map.on('click')` from being triggered
        let new_marker = marker.lng.toString().replace(/\./g, ',');
        toggle = !toggle;
        axios.get(`api/v1/meetups/${new_marker}/location`).then((response) => {
          if (toggle) {
            let card = document.getElementById(`${response.data[0].id}`);
            card.style.backgroundColor = '#eef7fa';
          } else {
            let card = document.getElementById(`${response.data[0].id}`);
            card.style.backgroundColor = '';
            card.style.color = 'rgba(0, 0, 0, 0.87)';
          }
        });
      });

      new mapboxgl.Marker(el).setLngLat([marker.lng, marker.lat]).addTo(map);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.markers !== prevProps.markers) {
      const map = new mapboxgl.Map({
        container: this.mapContainer,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [this.state.lng, this.state.lat],
        zoom: this.state.zoom,
      });
      map.addControl(new mapboxgl.NavigationControl());
      this.props.markers.forEach((marker) => {
        let el = document.createElement('div');
        el.className = 'marker';
        let toggle = false;
        el.addEventListener('click', function (e) {
          // Prevent the `map.on('click')` from being triggered
          let new_marker = marker.lng.toString().replace(/\./g, ',');
          toggle = !toggle;
          axios
            .get(`api/v1/meetups/${new_marker}/location`)
            .then((response) => {
              if (toggle) {
                let card = document.getElementById(`${response.data[0].id}`);
                card.style.backgroundColor = '#eef7fa';
                // card.style.color = 'white';
              } else {
                let card = document.getElementById(`${response.data[0].id}`);
                card.style.backgroundColor = '';
                card.style.color = 'rgba(0, 0, 0, 0.87)';
              }
            });
        });
        new mapboxgl.Marker(el).setLngLat([marker.lng, marker.lat]).addTo(map);
      });
    }
  }
  render() {
    return (
      <div>
        <div ref={(el) => (this.mapContainer = el)} className="mapContainer" />
      </div>
    );
  }
}

export default Map;
