import React from 'react';
import { Button } from '../Button/Button';
import 'react-calendar/dist/Calendar.css';

export class SidePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: [],
    };
  }

  render() {
    const { user } = this.props;
    let current_user = null;
    if (user) {
      current_user = <h1>{user.id}</h1>;
    }
    return (
      <div>
        <Button user={user} />
      </div>
    );
  }
}
