import React from "react";
import { useState, useEffect } from "react";
import { RegularMap } from "./Map/Map";
import swal from "sweetalert";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { Drawer } from "./Drawer/Drawer";
import SendIcon from "@material-ui/icons/Send";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import EventIcon from "@material-ui/icons/Event";
import ShareIcon from "@material-ui/icons/Share";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import AccessibleIcon from "@material-ui/icons/Accessible";
import { SimpleModal } from "./Modal/Modal";
import { Form } from "./Form/Form";
import HelmetMetaData from "../components/Helmet/Helmet";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { AlertDialog } from "./Like/Like";
import { Footer } from "../components/Footer/Footer";
import { Navbar } from "./Navbar/Navbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ShowDesktop } from "../components/ShowComponents/ShowDesktop";
import { ShowMobile } from "../components/ShowComponents/ShowMobile";

const Show = ({
  user,
  meetup,
  meetup: {
    id,
    title,
    description,
    address,
    location,
    max_people,
    created_at,
    flagged,
    uuid,
    start_time,
    end_time,
    start_date,
    user_photo,
    kids_friendly,
    wheelchair_access,
    user_id,
  },
  coord,
  attendees,
  pictures,
  meetup_owner: { google_url, photo, facebook_picture_url },
  meetup_owner,
  meetup_owner_name,
  messages,
  likes,
}) => {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [newAttendees, setAttendees] = useState(attendees);
  const [newMaximum, setMaximum] = useState(max_people);
  const [newMessages, setMessages] = useState(messages);
  const [newResolution, setResolution] = useState(false);
  let date = new Date(start_date);
  let monthName = months[date.getMonth()];
  let dayName = days[date.getDay()];

  const getScreenSize = () => {
    const resolution = window.matchMedia("(max-width: 610px)");
    setResolution(resolution.matches);
  };

  useEffect(() => getScreenSize());

  window.addEventListener("resize", () => getScreenSize());

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const getPicture = () => {
    const style = {
      width: "467px",
      height: "230px",
      objectFit: "cover",
      borderRadius: "24px",
    };
    return pictures.map((picture) => {
      return (
        <img
          className="carousel-picture"
          style={style}
          src={picture.photo.url}
        />
      );
    });
  };

  const handleAttendance = () => {
    event.preventDefault();
    let attendee = {
      name: user.name,
      meetup_id: id,
      meetup_uuid: uuid,
    };
    axios
      .post(`/attendees`, attendee)
      .then((response) => {
        axios.get(`/api/v1/attendees/${id}`).then((response) => {
          setAttendees(response.data);
          if (newMaximum > 0) {
            setMaximum(newMaximum - 1);
          }
        });
      })
      .catch((error) => console.log(error));
  };

  let user_picture = null;

  if (photo.url) {
    user_picture = <Avatar className="avatar-show" src={photo.url} />;
  } else if (facebook_picture_url) {
    user_picture = (
      <Avatar className="avatar-show" src={facebook_picture_url} />
    );
  } else if (google_url) {
    user_picture = <Avatar className="avatar-show" src={google_url} />;
  } else {
    user_picture = <Avatar className="avatar-show" />;
  }

  let visitingUser = null;
  if (user) {
    if (user.photo.url) {
      visitingUser = (
        <Avatar className="avatar-comments-container" src={user.photo.url} />
      );
    } else if (user.facebook_picture_url) {
      visitingUser = (
        <Avatar
          className="avatar-comments-container"
          src={user.facebook_picture_url}
        />
      );
    } else if (user.google_url) {
      visitingUser = (
        <Avatar className="avatar-comments-container" src={user.google_url} />
      );
    } else {
      visitingUser = <Avatar className="avatar-comments-container" />;
    }
  } else {
    visitingUser = <Avatar className="avatar-comments-container" />;
  }

  const handleMessageRequest = (event) => {
    let responseMessage = document.querySelector(".comment-input");
    let container = document.querySelector(".messages-container");
    let message = `<div id="message-notice" class="no-message">Message can't be blank!</div>`;
    let message_text = document.querySelector(".no-message");
    if (responseMessage.value) {
      let message = {
        content: responseMessage.value,
        uuid: uuid,
        user_id: user.id,
      };
      axios
        .post("/messages", message)
        .then((response) => {
          console.log(response);
          axios.get(`/api/v1/messages/${id}`).then((response) => {
            setMessages(response.data);
            responseMessage.value = "";
            if (message_text) message_text.remove();
          });
        })
        .catch((error) => console.log(error));
    } else {
      if (message_text) {
        message_text.innerHTML = "";
      }
      container.insertAdjacentHTML("afterend", message);
    }
  };

  let going_alert = null;
  if (user) {
    const user_filter = newAttendees.some(
      (attendee) => parseInt(attendee.user_id) == user.id
    );
    if (user_filter) {
      going_alert = (
        <React.Fragment>
          <div className="going-container">
            <p className="going-paragraph">You are going to the gathering</p>
          </div>
        </React.Fragment>
      );
    }
  }

  const handleAttendeeDelete = () => {
    if (user) {
      const user_id = newAttendees.find(
        (attendee) => parseInt(attendee.user_id) == user.id
      );
      axios
        .delete(`/attendees/${user_id.id}`)
        .then((response) => {
          console.log(response);
          let array = [...newAttendees];
          let filter = array.filter(
            (element) => parseInt(element.user_id) != user.id
          );
          setAttendees(filter);
          setMaximum(newMaximum + 1);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleSignup = () => {
    swal({
      text: "Please sign up to attend the gathering",
      icon: "info",
    });
  };

  const handleCalendar = () => {
    swal({
      text: "To use the calendar integration please sign up with your Google account",
      icon: "info",
    });
  };

  const handleEvent = () => {
    swal("Do you want to have this gathering in your Google calendar?", {
      buttons: {
        no: true,
        yes: true,
      },
    }).then((value) => {
      switch (value) {
        case "yes":
          let event = {
            event: {
              title: title,
              description: description,
              location: location,
              address: address,
              start_year: start_date.slice(0, 4).toString(),
              start_month: parseInt(start_date.slice(5, 7)).toString(),
              start_day: parseInt(start_date.slice(8, 10)).toString(),
              start_time_hour: parseInt(start_time.slice(11, 13)).toString(),
              start_time_minute: parseInt(start_time.slice(14, 16)).toString(),
              end_time_hour: parseInt(end_time.slice(11, 13)).toString(),
              end_time_minute: parseInt(end_time.slice(14, 16)).toString(),
            },
          };
          axios
            .post("/events", event)
            .then((response) => {
              console.log(response);
              swal({
                text: "Your Google calendar has been updated",
                icon: "success",
              });
            })
            .catch((error) => {
              console.log(error);
              swal({
                text: "Your Google calendar did not update",
                icon: "error",
              });
            });
          break;
        case "no":
          break;
      }
    });
  };

  const handleEndTime = (endTime) => {
    return parseInt(endTime.slice(11, 13)) >= 12
      ? endTime.slice(11, 16) + " " + "PM"
      : endTime.slice(11, 16) + " " + "AM";
  };

  const handleReply = () => {
    const dateValidator =
      new Date(
        Date.parse(start_date + " " + start_time.slice(11, 16).toString())
      ) < new Date();

    let user_filter;
    let attendeeCheck;
    if (user) {
      user_filter = newAttendees.some(
        (attendee) => parseInt(attendee.user_id) == user.id
      );
      attendeeCheck = attendees.some(
        (attendee) =>
          attendee.user_id == meetup.user_id && user.id === meetup.user_id
      );
    }
    if (user_filter) {
      return (
        <div
          className={
            !attendeeCheck
              ? "guests-button-container"
              : "guests-button-container-host"
          }
        >
          <p className="guest-number">
            You are going <span className="going-text">to the gathering</span>
          </p>
          {!attendeeCheck ? (
            <a onClick={() => handleAttendeeDelete()} className="edit-link">
              Edit RSVP
            </a>
          ) : (
            <a className="edit-link"> Thanks for hosting!</a>
          )}
        </div>
      );
    } else if (dateValidator) {
      return (
        <div className="guests-button-container">
          <p className="guest-number">{"Registrations are closed"}</p>
          <button className="button-sign-footer-disabled" disabled>
            Attend Gathering
          </button>
        </div>
      );
    } else if (newMaximum > 0) {
      return (
        <div className="guests-button-container">
          <p className="guest-number">{newMaximum + " " + "Spots left"}</p>
          <button
            onClick={!user ? () => handleSignup() : () => handleAttendance()}
            className={"button-sign-footer"}
          >
            Attend Gathering
          </button>
        </div>
      );
    } else {
      return (
        <div className="guests-button-container">
          <p className="guest-number">{"The gathering is full"}</p>
          <button className="button-sign-footer-disabled" disabled>
            Attend Gathering
          </button>
        </div>
      );
    }
  };

  return (
    <div
      className="main-body-container"
      style={{
        backgroundImage: `url('https://res.cloudinary.com/pelle/image/upload/v1624539727/Pelle-app-pictures-final/showfinal_k8c989.jpg')`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <HelmetMetaData
        title={title}
        description={description}
        image={pictures[0]}
        url={uuid}
      ></HelmetMetaData>
      <Navbar user={user} />
      <div>
        <div>{going_alert}</div>
        <div
          className={going_alert ? "icons-container-going" : "icons-container"}
        >
          <p className="start-date">
            {dayName +
              ", " +
              date.getDate() +
              " " +
              monthName +
              " " +
              date.getFullYear()}
          </p>
          <h2 className="show-main-title">{title}</h2>
          <div className="share-icons-container">
            <div className="avatar-container">
              <a href={`/users/${user_id}/profile`}>{user_picture}</a>
              <div className="meetup-owner">
                <p>Hosted By</p>
                <p className="meetup-owner-name">{meetup_owner_name}</p>
              </div>
            </div>
            <div className="share-icons">
              <div className="icons">
                {user && user.refresh_token != null ? (
                  <EventIcon className="show-icon" onClick={handleEvent} />
                ) : (
                  <EventBusyIcon
                    className="show-icon"
                    onClick={handleCalendar}
                  />
                )}
                <AlertDialog meetup={meetup} likes={likes} user={user} />
                <Form
                  uuid={uuid}
                  flagged={flagged}
                  title={title}
                  description={description}
                  image={pictures[0]}
                />
                <SimpleModal
                  user={user}
                  meetup_owner={meetup_owner}
                  meetup={meetup}
                />
                <p className="contact-host">Contact the Host</p>
              </div>
            </div>
          </div>
        </div>
        {/*  Working for Mobile and Desktop */}
        {newResolution ? (
          <ShowMobile
            click={() => handleMessageRequest()}
            {...{
              pictures,
              description,
              newAttendees,
              visitingUser,
              newMessages,
              address,
              start_time,
              dayName,
              date,
              monthName,
              handleEndTime,
              end_time,
              kids_friendly,
              wheelchair_access,
              coord,
            }}
          />
        ) : (
          <ShowDesktop
            click={() => handleMessageRequest()}
            {...{
              pictures,
              description,
              newAttendees,
              visitingUser,
              newMessages,
              address,
              start_time,
              dayName,
              date,
              monthName,
              handleEndTime,
              end_time,
              kids_friendly,
              wheelchair_access,
              coord,
            }}
          />
        )}
      </div>
      <div className="alert-container">
        <div className="alert-information-container">
          <p className="start-date-alert">
            {dayName +
              ", " +
              date.getDate() +
              " " +
              monthName +
              " " +
              date.getFullYear()}
          </p>
          <p className="start-date-title">{title}</p>
        </div>
        {handleReply(id)}
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );
};

export default Show;
