import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import { LikeAnimation } from '../LikeAnimation/LikeAnimation';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      height: 260,
      marginTop: 230,
    },
  },
}));

export const AlertDialog = ({ meetup, likes, user }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [newLikes, setLikes] = useState(likes);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLike = () => {
    const like = {
      meetup_id: meetup.id,
    };
    if (user) {
      axios
        .post(`/likes`, like)
        .then((response) => {
          console.log(response);
          handleClose();
        })
        .catch((error) => handleClose());

      setTimeout(() => {
        axios
          .get(`/api/v1/likes/${meetup.id}`)
          .then((response) => {
            console.log(response);
            setLikes(response.data);
          })
          .catch((error) => console.log(error));
      }, 1000);
    } else {
      swal({
        text: 'You need to sign in to like a gathering',
        icon: 'info',
      });
      handleClose();
    }
  };

  return (
    <div>
      {newLikes && newLikes.length > 0 ? (
        <FavoriteIcon className="show-icon-liked" />
      ) : (
        <FavoriteBorderIcon className="show-icon" onClick={handleClickOpen} />
      )}
      <Dialog
        open={open}
        className={classes.root}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseIcon onClick={handleClose} className="close-icon" />
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="modal-text"
          >
            <div className="like-modal-information">
              <LikeAnimation className="like" />
              <p className="paragraph-like">
                Every liked gathering will be stored in your dashboard under
                <span className="liked-gatherings-modal-text">
                  Liked Gatherings
                </span>{' '}
                for you to revisit and attend.
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="modal-btn" onClick={handleLike}>
            Like Gathering
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
