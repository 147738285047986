import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import InfoIcon from '@material-ui/icons/Info';
import { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { VictoryPie, VictoryBar, VictoryChart } from 'victory';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { DashboardCard } from '../components/DashboardCard/DashboardCard';
import { LikedCard } from '../components/LikedCard/LikedCard';
import { Footer } from './Footer/Footer';
import { DashboardUserCard } from '../components/DashboardUserCard/DashboardUserCard';
import Carousel from 'react-multi-carousel';
import { Navbar } from './Navbar/Navbar';
import 'react-multi-carousel/lib/styles.css';

const Dashboard = ({
  user,
  gatherings,
  attendees,
  meetups_liked,
  attended,
  attended_gatherings,
  meetupsPerAttendee,
  pictures,
}) => {
  const [newGatherings, setGatherings] = useState(gatherings);
  const [newAttendedGatherings, setAttendedGatherings] = useState(
    attended_gatherings
  );

  const getNumberOfAttendees = (day) => {
    const getAttendees = meetupsPerAttendee.map((_, index) => {
      return meetupsPerAttendee[index][day];
    });
    return getAttendees.filter((attendees) => attendees != undefined).shift();
  };

  const { length } = gatherings;

  const wantedGraphicData = [{ y: 0 }, { y: 100 }];

  const setGraphicData =
    length > 0 || attended.length > 0
      ? [{ x: 'test', y: length }, { y: attended.length }]
      : [{ x: 'test', y: 25, fill: '#c43a31' }, { y: 25 }];

  const [getAnimation, setAnimation] = useState(wantedGraphicData);

  useEffect(() => {
    setAnimation(setGraphicData);
  }, []);

  let new_date = new Date();

  const mark = attended_gatherings.map((gathering) => {
    if (new Date(gathering.start_date) >= new_date) return gathering.start_date;
  });

  const userGatherings = newAttendedGatherings.map((gathering, index) => {
    const {
      id,
      title,
      address,
      start_date,
      start_time,
      user_id,
      uuid,
      user_photo,
    } = gathering;

    return (
      <div>
        <DashboardCard
          keys={id}
          title={title}
          address={address}
          start_date={start_date}
          start_time={start_time}
          cardId={index}
          userId={user_id}
          uuid={uuid}
          userPhoto={user_photo}
          pictures={pictures}
        />
      </div>
    );
  });

  const handleDelete = (uuid) => {
    swal('Do you want to delete this gathering?', {
      buttons: {
        no: true,
        yes: true,
      },
    }).then((value) => {
      switch (value) {
        case 'yes':
          axios
            .delete(`/gatherings/${uuid}`)
            .then((response) => {
              axios.get(`/api/v1/meetups/${user.id}/total`).then((response) => {
                setGatherings(response.data);
              });
              setAttendedGatherings(
                newAttendedGatherings.filter(
                  (gathering) => gathering.uuid != uuid
                )
              );
            })
            .catch((error) => console.log(error));
          break;
        case 'no':
          break;
      }
    });
  };

  const userOwnGatherings = newGatherings.map((gathering) => {
    const { title, start_date, uuid, start_time } = gathering;

    return (
      <div>
        <DashboardUserCard
          title={title}
          startingDate={start_date}
          startingTime={start_time}
          uuid={uuid}
          click={() => handleDelete(uuid)}
        />
      </div>
    );
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const likedGatherings = meetups_liked.map((gathering) => {
    const { title, start_date, uuid } = gathering;

    return (
      <div>
        <LikedCard title={title} startingDate={start_date} uuid={uuid} />
      </div>
    );
  });

  return (
    <div className="dashboard-body-container">
      <Navbar user={user} />
      <div className="graph-container">
        <div>
          <h1 className="dashboard-title">Dashboard</h1>
        </div>
        <div className="charts-container">
          <p className="gatherings-dashboard-bar-mobile">Total Gatherings</p>
          <div className="dashboard-pie-chart">
            <VictoryPie
              data={getAnimation}
              innerRadius={80}
              animate={{
                duration: 2000,
              }}
              style={{ labels: { display: 'none' } }}
              colorScale={['#ffcb46', '#165250']}
            />
          </div>
          <div className="analytics-container-listed-mobile">
            <div className="gatherings-listed-mobile">
              <p className="gatherings-listed-number-mobile">{length}</p>
              <p className="gatherings-listed-text-mobile">Gatherings Listed</p>
            </div>
          </div>
          <div className="analytics-container-attended-mobile">
            <div className="gatherings-listed-mobile">
              <p className="gatherings-listed-number-mobile">{attended.length}</p>
              <p className="gatherings-listed-two-mobile">Gatherings Attended</p>
            </div>
          </div>
          <div>
            <p className="gatherings-dashboard-bar">Total Attendees</p>
            <div className="dashboard-bar-chart">
              <VictoryChart domainPadding={14} domain={{ y: [0, 10] }}>
                <VictoryBar
                  width={600}
                  style={{
                    data: { fill: '#FF5D73', width: 30 },
                    labels: { fontFamily: 'Roboto', fontSize: 12 },
                  }}
                  cornerRadius={{ top: 6 }}
                  categories={{
                    x: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                  }}
                  data={[
                    { x: 'Mon', y: getNumberOfAttendees('Mon') },
                    { x: 'Tue', y: getNumberOfAttendees('Tue') },
                    { x: 'Wed', y: getNumberOfAttendees('Wed') },
                    { x: 'Thu', y: getNumberOfAttendees('Thu') },
                    { x: 'Fri', y: getNumberOfAttendees('Fri') },
                    { x: 'Sat', y: getNumberOfAttendees('Sat') },
                    { x: 'Sun', y: getNumberOfAttendees('Sun') },
                  ]}
                />
              </VictoryChart>
              <div className="attendees-infographic">
                <p className="attendees-text">
                  Since you've joined <strong>Pelle</strong>
                  <span
                    className={
                      attendees >= 10
                        ? 'number-of-attendees-numbers'
                        : 'number-of-attendees'
                    }
                  >
                    {attendees >= 1 ? attendees : 0}
                  </span>{' '}
                  people have joined your gatherings. Keep the amazing work!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="analytics-container-listed">
          <div className="gatherings-listed">
            <p className="gatherings-listed-number">{length}</p>
            <p className="gatherings-listed-text">Gatherings Listed</p>
          </div>
        </div>
        <div className="analytics-container-attended">
          <div className="gatherings-listed">
            <p className="gatherings-listed-number">{attended.length}</p>
            <p className="gatherings-listed-two">Gatherings Attended</p>
          </div>
        </div>
      </div>
      <div className="calendar-liked-gatherings-container">
        <div className="calendar-container">
          <p className="calendar-title">Your Calendar</p>
          <Calendar
            tileClassName={({ date }) => {
              if (mark.find((x) => x === moment(date).format('YYYY-MM-DD')))
                return 'highlight';
            }}
          />
        </div>
        <div className="liked-gatherings-container">
          <p className="liked-gatherings-title">Liked Gatherings</p>
          <div className="liked-gatherings-cards">{likedGatherings}</div>
        </div>
      </div>
      <div className="user-gatherings-information-container">
        <p className="user-gatherings-title">Upcoming Gatherings</p>
        <div className="user-gatherings-cards">
          <Carousel responsive={responsive}>{userGatherings}</Carousel>
        </div>
      </div>
      <div className="own-gatherings-container">
        <p className="own-gatherings-title">Your Gatherings</p>
        <div className="own-gatherings-carousel">
          <Carousel responsive={responsive}>{userOwnGatherings}</Carousel>
        </div>
      </div>

      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
