import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { useState, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PersonIcon from "@material-ui/icons/Person";
import { Notifications } from "../Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Modal from "@material-ui/core/Modal";
import NaturePeopleIcon from "@material-ui/icons/NaturePeople";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import axios from "axios";

export const Navbar = ({ user }) => {
  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
  });

  const classes = useStyles();
  const [navLinks, setnavLinks] = useState([
    "Notifications",
    "Dashboard",
    "Profile",
    "Create gathering",
    "Attend gathering",
    "Logout",
  ]);

  const [state, setState] = useState({
    right: false,
  });

  const handleLogout = () => {
    axios
      .get("/users/sign_out", {
        params: {
          authenticity_token: $('meta[name="csrf-token"]').attr("content"),
        },
      })
      .then(function (response) {
        console.log(response.data);
        window.location.href = `/`;
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ right: open });
  };

  const handleSignUp = () => {
    return (
      <div className="navbar-buttons-container">
        <a href={`/users/sign_up`}>
          <button className="button-sign">Sign Up</button>
        </a>
        <a href={`/users/sign_in`}>
          <button className="button-sign">Log In</button>
        </a>
      </div>
    );
  };

  const navList = (text) => {
    if (text === "Notifications") {
      return (
        <Notifications onClose={toggleDrawer("right", false)} text={text} />
      );
    }
    if (text === "Logout") {
      return (
        <ListItem
          onClick={() => {
            handleLogout();
          }}
          button
          key={text}
        >
          <ListItemIcon>
            <ExitToAppIcon className="navigation-icons" />
          </ListItemIcon>
          <ListItemText className="navbar-text" primary={text} />
        </ListItem>
      );
    }
    if (text === "Profile") {
      return (
        <a href={user ? `/users/${user.id}/edit` : "/"}>
          <ListItem onClick={toggleDrawer("right", false)} button key={text}>
            <ListItemIcon>
              <PersonIcon className="navigation-icons" />
            </ListItemIcon>
            <ListItemText className="navbar-text" primary={text} />
          </ListItem>
        </a>
      );
    }
    if (text === "Dashboard") {
      return (
        <a href={user ? `/dashboard/${user.id}` : "/"}>
          <ListItem onClick={toggleDrawer("right", false)} button key={text}>
            <ListItemIcon>
              <EqualizerIcon className="navigation-icons" />
            </ListItemIcon>
            <ListItemText className="navbar-text" primary={text} />
          </ListItem>
        </a>
      );
    }

    if (text === "Create gathering") {
      return (
        <a href={`/gatherings/new`}>
          <ListItem onClick={toggleDrawer("right", false)} button key={text}>
            <ListItemIcon>
              <NaturePeopleIcon className="navigation-icons" />
            </ListItemIcon>
            <ListItemText className="navbar-text" primary={text} />
          </ListItem>
        </a>
      );
    }
    if (text === "Attend gathering") {
      return (
        <a href={`/all`}>
          <ListItem onClick={toggleDrawer("right", false)} button key={text}>
            <ListItemIcon>
              <GroupAddIcon className="navigation-icons" />
            </ListItemIcon>
            <ListItemText className="navbar-text" primary={text} />
          </ListItem>
        </a>
      );
    }
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <List>{navLinks.map((text) => navList(text))}</List>
      ;
      <Divider className="navbar-divider" />
    </div>
  );

  return (
    <div className="navbar-container">
      <div className="navbar-elements-position">
        <div className="avatar">
          <a href={`/`}>
            <Avatar className="logo" alt="Remy Sharp" src="/assets/pelle.png" />
          </a>
        </div>
        {user
          ? ["right"].map((anchor) => (
              <div key={anchor}>
                <MenuIcon
                  className="menu-icon"
                  onClick={toggleDrawer(anchor, true)}
                >
                  {anchor}
                </MenuIcon>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </div>
            ))
          : handleSignUp()}
      </div>
    </div>
  );
};
