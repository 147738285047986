import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetMetaData = (props) => {
  let currentUrl = 'pelle.live/gatherings/' + props.url;
  let title =
    props.title !== undefined ? props.title : 'Pelle - Gather with a purpose';
  let image =
    props.image !== undefined
      ? props.image
      : 'https://res.cloudinary.com/pelle/image/upload/v1605355181/Screen_Shot_2020-11-14_at_12.59.04_PM_vnx30t.png';
  let description =
    props.description !== undefined
      ? props.description
      : 'Pelle is a community of people that share the same mindset. Make our cities and environment cleaner and sustainable';
  return (
    <Helmet>
      <title>{title}</title>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="Pelle" />
      <meta property="og:description" content={description} />{' '}
    </Helmet>
  );
};

export default HelmetMetaData;
