import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import axios from "axios";
import swal from "sweetalert";
import moment, * as moments from "moment";

export const DashboardUserCard = (props) => {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  // Deconstructions of props
  const { startingDate, uuid, title, click } = props;

  let date = new Date(startingDate);

  let monthName = months[date.getMonth()];

  let dayName = days[date.getDay()];

  const dateToday = new Date();

  const handleEdit = (uuid) => {
    window.location.href = `/gatherings/${uuid}/edit`;
  };

  const handleInformation = (uuid) => {
    window.location.href = `/gatherings/${uuid}`;
  };

  const dateValidator = moment(startingDate).isBefore(moment());

  return (
    <div>
      <a className="dashboarduser-link">
        <div className="dashboarduser-container">
          <div className="dashboarduser-information-container">
            <div className="edit-container">
              <p className="dashboarduser-star-date">
                {dayName +
                  ", " +
                  date.getDate() +
                  " " +
                  monthName +
                  " " +
                  date.getFullYear()}
              </p>
              {!dateValidator ? (
                <EditIcon
                  className="edit-icon"
                  onClick={() => handleEdit(uuid)}
                />
              ) : null}
              {!dateValidator ? (
                <DeleteForeverIcon className="delete-icon" onClick={click} />
              ) : null}
              <InfoIcon
                className="info-icon"
                onClick={() => handleInformation(uuid)}
              />
            </div>
            <p className="dashboarduser-title">{title}</p>
          </div>
        </div>
      </a>
    </div>
  );
};
