import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    borderRadius: 8,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export const DashboardCard = ({
  title,
  address,
  start_date,
  start_time,
  cardId,
  userId,
  uuid,
  userPhoto,
  pictures,
  id,
}) => {
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  let days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  let date = new Date(start_date);
  let monthName = months[date.getMonth()];
  let dayName = days[date.getDay()];
  const classes = useStyles();
  let picture_new = [];
  let picture = null;
  pictures.map((picture) => {
    if (picture.meetup_id == id) {
      picture_new.push(picture.photo.url);
    }
  });
  if (picture_new.length != 0) {
    picture = (
      <CardMedia
        className={classes.media}
        image={picture_new[0]}
        title={title}
      />
    );
  } else {
    picture = (
      <CardMedia
        className={classes.media}
        image={`https://source.unsplash.com/1600x900/?amsterdam${cardId}`}
        title={title}
      />
    );
  }
  return (
    <div className="dashboard-card-background">
      <Card
        className={
          cardId % 2 == 0 ? 'first-card-dashboard' : 'second-card-dashboard'
        }
      >
        <CardHeader
          avatar={
            <a href={`/users/${userId}/profile`}>
              <Avatar
                src={userPhoto}
                aria-label="recipe"
                className={classes.avatar}
                id={cardId % 2 == 0 ? 'first-avatar' : 'second-avatar'}
              ></Avatar>
            </a>
          }
          title={title.slice(0, 45)}
        />
        <a href={`/gatherings/` + uuid}>
          <div className="picture-card-container">{picture}</div>
        </a>
        <a href={`/gatherings/` + uuid}>
          <CardContent>
            <br></br>
            <div className="dashboard-card-information">
              <Typography id="address" variant="body2" component="p">
                <div id="address-text-card">
                  <LocationOnIcon
                    className={
                      cardId % 2 == 0 ? 'first-text-icon' : 'second-text-icon'
                    }
                  />
                  <div className="text-address-div">
                    <p
                      className={
                        cardId % 2 == 0
                          ? 'text-address-card-one-dashboard'
                          : 'text-address-card-two-dashboard'
                      }
                    >
                      {address.length > 30
                        ? address.slice(0, 30) + '...'
                        : address}
                    </p>
                  </div>
                </div>
              </Typography>
              <Typography
                id="address"
                variant="body2"
                color="textSecondary"
                component="p"
              >
                <div id="time-text-dashboard">
                  <ScheduleIcon
                    className={
                      cardId % 2 == 0 ? 'first-text-icon' : 'second-text-icon'
                    }
                  />
                  <div
                    className={
                      cardId % 2 == 0
                        ? 'card-time-date-information-one'
                        : 'card-time-date-information-two'
                    }
                  >
                    <p>{dayName + ', ' + date.getDate() + ' ' + monthName}</p>
                    <p className="time-card-information">
                      {parseInt(start_time.slice(11, 16)) >= 12
                        ? start_time.slice(11, 16) + ' ' + 'PM'
                        : start_time.slice(11, 16) + ' ' + 'AM'}
                    </p>
                  </div>
                </div>
              </Typography>
            </div>
          </CardContent>
        </a>
        <hr></hr>
        <CardActions disableSpacing></CardActions>
      </Card>
    </div>
  );
};
