import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FavoriteIcon from "@material-ui/icons/Favorite";
import InfoIcon from "@material-ui/icons/Info";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ShareIcon from "@material-ui/icons/Share";
import FlagIcon from "@material-ui/icons/Flag";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import { CardShare } from "../CardShare/CardShare";
import { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import { ImageSource } from "mapbox-gl";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import { CardLike } from "../CardLike/CardLike";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 205,
    paddingTop: "56.25%",
    borderRadius: 8,
    // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CardTemplate = ({
  meetup,
  pictures,
  user,
  cardId,
  index,
  attendees,
  likes,
}) => {
  const classes = useStyles();
  const {
    title,
    description,
    max_people,
    start_date,
    location,
    start_time,
    address,
    id,
    uuid,
    user_photo,
    flagged,
    total_attendees,
  } = meetup;
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let date = new Date(start_date);
  let monthName = months[date.getMonth()];
  let dayName = days[date.getDay()];
  let picture_new = [];
  let picture = null;
  pictures.map((picture) => {
    if (picture.meetup_id == id) {
      picture_new.push(picture.photo.url);
    }
  });
  if (picture_new.length != 0) {
    picture = (
      <CardMedia
        className={classes.media}
        image={picture_new[0]}
        title={title}
      />
    );
  } else {
    picture = (
      <CardMedia
        className={classes.media}
        image={`https://source.unsplash.com/1600x900/?amsterdam${index}`}
        title={title}
      />
    );
  }
  const likesFilter = user
    ? likes.filter(
        (like) => like.meetup_id == meetup.id && like.user_id == user.id
      )
    : null;

  return (
    <div className="card-background">
      <Card className={cardId % 2 == 0 ? "first-card" : "second-card"}>
        <CardHeader
          avatar={
            <a href={`/users/${meetup.user_id}/profile`}>
              <Avatar
                src={user_photo}
                aria-label="recipe"
                className={classes.avatar}
                id={cardId % 2 == 0 ? "first-avatar" : "second-avatar"}
              ></Avatar>
            </a>
          }
          title={title.slice(0, 45)}
        />
        <a href={`/gatherings/` + uuid}>
          <div className="picture-card-container">{picture}</div>
        </a>
        <a href={`/gatherings/` + uuid}>
          <CardContent>
            <Typography
              className={cardId % 2 == 0 ? "first-text" : "second-text"}
              variant="body2"
              component="p"
            >
              {description.slice(0, 120) + "..."}
            </Typography>
            <br></br>
            <Typography id="address" variant="body2" component="p">
              <div id="address-text-card">
                <LocationOnIcon
                  className={
                    cardId % 2 == 0 ? "first-text-icon" : "second-text-icon"
                  }
                />
                <div className="text-address-div">
                  <p
                    className={
                      cardId % 2 == 0
                        ? "text-address-card-one"
                        : "text-address-card-two"
                    }
                  >
                    {address.length > 30
                      ? address.slice(0, 30) + "..."
                      : address}
                  </p>
                </div>
              </div>
            </Typography>
            <Typography
              id="address"
              variant="body2"
              color="textSecondary"
              component="p"
            >
              <div id="time-text">
                <ScheduleIcon
                  className={
                    cardId % 2 == 0 ? "first-text-icon" : "second-text-icon"
                  }
                />
                <div
                  className={
                    cardId % 2 == 0
                      ? "card-time-date-information-one"
                      : "card-time-date-information-two"
                  }
                >
                  <p>{dayName + ", " + date.getDate() + " " + monthName}</p>
                  <p className="time-card-information">
                    {parseInt(start_time.slice(11, 16)) >= 12
                      ? start_time.slice(11, 16) + " " + "PM"
                      : start_time.slice(11, 16) + " " + "AM"}
                  </p>
                </div>
              </div>
            </Typography>
            <Typography
              id="address"
              variant="body2"
              color="textSecondary"
              component="p"
            >
              <div id="time-text">
                <EmojiPeopleIcon
                  className={
                    cardId % 2 == 0 ? "first-text-icon" : "second-text-icon"
                  }
                />
                <div
                  className={
                    cardId % 2 == 0
                      ? "card-time-date-information-one"
                      : "card-time-date-information-two"
                  }
                >
                  <p className="attendees-card-information">
                    {total_attendees - attendees > 0
                      ? total_attendees - attendees + " " + "spots available"
                      : "Gathering is full"}
                  </p>
                </div>
              </div>
            </Typography>
          </CardContent>
        </a>

        <CardActions disableSpacing>
          <IconButton aria-label="share">
            <CardShare cardId={cardId} uuid={uuid} />
          </IconButton>
          <IconButton aria-label="like">
            <CardLike
              meetup={meetup}
              user={user}
              likes={likesFilter}
              cardId={cardId}
            />
          </IconButton>
          <IconButton aria-label="share">
            <a href={`/gatherings/${uuid}`}>
              <InfoIcon
                className={cardId % 2 == 0 ? "card-info-one" : "card-info-two"}
              />
            </a>
          </IconButton>
        </CardActions>
      </Card>
    </div>
  );
};
