import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FavoriteIcon from "@material-ui/icons/Favorite";
import InfoIcon from "@material-ui/icons/Info";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ShareIcon from "@material-ui/icons/Share";
import FlagIcon from "@material-ui/icons/Flag";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import { CardShare } from "../CardShare/CardShare";
import { useState, useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import { ImageSource } from "mapbox-gl";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import GroupsIcon from "@material-ui/icons/Group";
import { CardLike } from "../CardLike/CardLike";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 205,
    paddingTop: "56.25%",
    borderRadius: 8,
    // 16:9
  },
  default: {
    height: 230,
    paddingTop: "56.25%",
    // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export const MobileCardTemplate = ({
  meetup,
  pictures = null,
  user,
  cardId,
  index,
  attendeesLength,
  likes,
  attendees,
}) => {
  const [gatheringImage, setGatheringImage] = useState(null);
  let picture_new = [];
  let picture = null;
  useEffect(() => {
    const getPhoto = async () => {
      const url = `https://api.unsplash.com/search/photos?page=1&query=${
        address + " " + location
      }`;
      const request = await fetch(url, {
        headers: {
          Authorization: `Client-ID`,
        },
      });
      if (request.ok) {
        const response = await request.json();
        const [firstPicture] = response.results;
        setGatheringImage(firstPicture.urls.raw);
      } else {
        setGatheringImage(
          "https://images.unsplash.com/photo-1519475889208-0968e5438f7d?ixid=M3w2NDc3NDF8MHwxfHNlYXJjaHwxfHxBbmRyaWVzJTIwQ29waWVyc3RyYWF0JTIwJTIwQW1zdGVyZGFtfGVufDB8fHx8MTcyNDg0MjY0OHww&ixlib=rb-4.0.3"
        );
      }
    };
    getPhoto();
  }, []);
  const classes = useStyles();
  const {
    title,
    start_date,
    location,
    start_time,
    address,
    id,
    uuid,
    user_photo,
    user_id,
    total_attendees,
  } = meetup;
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let date = new Date(start_date);
  let monthName = months[date.getMonth()];
  let dayName = days[date.getDay()];

  pictures.map((picture) => {
    if (picture.meetup_id == id) {
      picture_new.push(picture.photo.url);
    }
  });
  if (picture_new.length != 0) {
    picture = (
      <CardMedia
        className={classes.media}
        image={picture_new[0]}
        title={title}
      />
    );
  } else {
    picture = (
      <CardMedia
        className={classes.default}
        image={gatheringImage}
        title={title}
      />
    );
  }

  const likesFilter = user
    ? likes.filter(
        (like) => like.meetup_id == meetup.id && like.user_id == user.id
      )
    : null;

  return (
    <div className="mobile-card-container">
      <div className="mobile-card-title">
        <a href={`/users/${user_id}/profile`}>
          <Avatar
            src={user_photo}
            aria-label="recipe"
            className={classes.avatar}
            id={"first-avatar"}
          ></Avatar>
        </a>
        <span className="mobile-card-title-event">{title}</span>
      </div>
      <div className="mobile-card-picture">{picture}</div>
      <div className="mobile-card-menu">
        <div className="mobile-card-section">
          <div className="mobile-card-location">
            <LocationOnIcon className="first-text-icon" />
            <span className="mobile-card-location-text">
              {address.length > 25
                ? address.slice(0, 25) + "..."
                : address + ", " + location}
            </span>
          </div>
          <div className="mobile-card-time">
            <ScheduleIcon className="first-text-icon" />
            <div className="mobile-card-timestamp">
              <span>{dayName + " " + date.getDate() + " " + monthName}</span>
              <span className="mobile-card-information">
                {parseInt(start_time.slice(11, 16)) >= 12
                  ? start_time.slice(11, 16) + " " + "PM"
                  : start_time.slice(11, 16) + " " + "AM"}
              </span>
            </div>
          </div>
        </div>
        <div className="mobile-card-section">
          <div className="mobile-card-attendees">
            <EmojiPeopleIcon className="first-text-icon" />
            <span className="mobile-card-attendees-information">
              {total_attendees - attendeesLength > 0
                ? total_attendees - attendeesLength + " " + "spots available"
                : "Gathering is full"}
            </span>
          </div>
          <div className="mobile-card-avatars">
            <GroupsIcon className="first-text-icon" />
            <div className="mobile-card-avatar-container">
              {attendees &&
                attendees.slice(0, 3).map((attendee, index) => {
                  return (
                    <Avatar
                      src={attendee.photo}
                      aria-label="recipe"
                      className="mobile-avatar-attendees"
                      id={index}
                    ></Avatar>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="mobile-card-actions">
          <CardShare
            cardId={cardId}
            uuid={uuid}
            className="mobile-card-share"
          />
          <CardLike
            meetup={meetup}
            user={user}
            likes={likesFilter}
            cardId={cardId}
            className="mobile-card-like"
          />
          <a href={`/gatherings/${uuid}`}>
            <InfoIcon className="card-info-one" />
          </a>
        </div>
      </div>
    </div>
  );
};
