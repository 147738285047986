import React from 'react';
import Modal from '@material-ui/core/Modal';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useReducer } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';

export const SimpleModal = ({ meetup_owner, meetup, user }) => {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [newMessage, setMessage] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      contact: '',
      email: '',
      text: '',
    }
  );

  const handleRequest = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setMessage({ [name]: value });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleAlert = () => {
    swal('Please sign in to send emails to Organizers');
  };
  const handleSubmit = () => {
    let email = {
      meetupOwner: meetup_owner.name,
      contact: user.name,
      email: user.email,
      text: newMessage.message,
      user_email: meetup_owner.email,
      subject: meetup.title,
    };
    axios.post('/email', email).then((response) => {
      console.log(response);
      swal(
        'Email sent!',
        'The Organizer will reply directly to your email',
        'success',
        {
          buttons: {
            ok: true,
          },
        }
      ).then((value) => {
        switch (value) {
          case 'ok':
            setOpen(false);
            break;
        }
      });
    });
  };

  const body = (
    <div>
      <div className="modal-container">
        <CloseIcon className="close-icon" onClick={() => handleClose()} />
        <div className="labels-form-container">
          <div className="drawer-titles">
            <h3 className="modal-header">Contact - {meetup_owner.name}</h3>
          </div>
          <div className="form-container-small">
            <input
              name="contact"
              id="name-input"
              className="drawer-form"
              value={user ? user.name : ''}
              onSubmit={handleRequest}
            ></input>
          </div>
          <div className="form-container-small">
            {user ? (
              <input
                value={user ? user.email : 'Please sign up'}
                name="email"
                className="drawer-form"
                onSubmit={handleRequest}
              ></input>
            ) : (
              <input
                value={''}
                name="email"
                className="drawer-form"
                onChange={handleRequest}
              ></input>
            )}
          </div>
          <div className="form-container-small">
            <textarea
              name="message"
              placeholder={'Your Message...'}
              id="drawer-textarea"
              className="drawer-form"
              onChange={handleRequest}
              required
            ></textarea>
          </div>
          <div className="form-container-small">
            {user ? (
              <button className="modal-btn" onClick={handleSubmit}>
                Submit
              </button>
            ) : (
              <button className="modal-btn" onClick={handleAlert}>
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <MailOutlineIcon className="show-icon" onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};
