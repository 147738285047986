import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ShareIcon from "@material-ui/icons/Share";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import HelmetMetaData from "../Helmet/Helmet";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CardShare = ({
  uuid,
  flagged,
  title,
  description,
  image,
  card,
  cardId,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMessage = (event) => {
    let message = event.target;
    message.select();
    document.execCommand("copy");
  };

  return (
    <div className="form-modal-container">
      <HelmetMetaData></HelmetMetaData>
      <ShareIcon
        id="share-button-form"
        variant="outlined"
        color="primary"
        className={cardId % 2 == 0 ? "share-icon-one" : "share-icon-two"}
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <CloseIcon onClick={handleClose} className="close-icon" />
        <DialogTitle id="alert-dialog-slide-title">
          {"Spread the Word!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            We want to create an impact in our community. If you want to involve
            your network please click to share this specific link.
          </DialogContentText>
          {flagged ? (
            <TextField
              onClick={handleMessage}
              autoFocus
              margin="dense"
              id="name"
              label="Share the link with your friends"
              type="text"
              value={
                "This post is under review - It has been flagged by other users"
              }
              fullWidth
            ></TextField>
          ) : (
            <TextField
              onClick={handleMessage}
              autoFocus
              margin="dense"
              id="name"
              label="Share the link with your friends"
              type="text"
              value={"https://www.pelle.live/gatherings/" + uuid}
              fullWidth
            ></TextField>
          )}
        </DialogContent>
        <div className="social-media-share">
          <div className="social-icon">
            <FacebookShareButton
              url={"pelle.live/gatherings/" + uuid}
              quote={"Gather with a purpose"}
              hashtag="#environment"
              image={
                "https://res.cloudinary.com/pelle/image/upload/v1605355181/Screen_Shot_2020-11-14_at_12.59.04_PM_vnx30t.png"
              }
            >
              <FacebookIcon size={40} />
            </FacebookShareButton>
          </div>
          <div className="social-icon">
            <WhatsappShareButton
              url={"https://www.pelle.live/gatherings/" + uuid}
              title={"Pelle - Gather with a purpose"}
              separator=":: "
            >
              <WhatsappIcon size={40} />
            </WhatsappShareButton>
          </div>
          <div className="social-icon">
            <EmailShareButton
              url={"\nLink: https://www.pelle.live/gatherings/" + uuid}
              body={
                "Hey there, pls share the gathering and let's clean our city 😀"
              }
              subject={title}
            >
              <EmailIcon size={40} />
            </EmailShareButton>
          </div>
          <div className="social-icon">
            <FacebookMessengerShareButton
              url={"https://www.pelle.live/gatherings/" + uuid}
              quote={`Let's gather with purpose ${
                "https://www.pelle.live/gatherings/" + uuid
              }`}
              appId={"1131403263907615"}
            >
              <FacebookMessengerIcon size={40} />
            </FacebookMessengerShareButton>
          </div>
          <div className="social-icon">
            <TwitterShareButton
              url={"https://www.pelle.live/gatherings/" + uuid}
              title={`Let's gather with purpose - Cleaning the cities one step at a time`}
              hashtags={["cleaning", "amsterdam", "gatherings"]}
            >
              <TwitterIcon size={40} />
            </TwitterShareButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
